<template>
  <BasePage :isLoaded="!!VirtualTour" disableFooter disablePreFooter class="fill-height">
    <template v-slot:content>
      <v-container fill-height>
        <div class="vt-bg-1 -below"></div>
        <div class="vt-bg-2 -below"></div>
        <v-row align="center" class="fill-height -above" no-gutters>
          <v-col cols="12" class="fill-height">
            <div
              v-for="(item, index) in VirtualTour.virtualTourMediaList"
              :key="item.id"
              :class="{
                [`vt-media-${index}`]: true,
                'vt-media-vertical': item.isVertical
               }"
              class="vt-media my-12 my-md-0"
            >
              <div>
                <VideoPlayer
                  v-if="item._repeaterType === 'Video'"
                  :ratio="item.isVertical ? (3 / 4) : (16 / 9)"
                  :video="item.video"
                  :loop="true"
                  :muted="true"
                  :autoplay="true"
                  :controls="false"
                />
                <v-img
                  v-if="item._repeaterType === 'Image'"
                  :aspect-ratio="item.isVertical ? (3 / 4) : (16 / 9)"
                  :lazy-src="getLazySrc(item.image)"
                  :src="optimizedImageURL(item.image, { width: 600, quality: 95 })"
                />
              </div>
              <MarkdownBlock
                v-if="!!item.text"
                :linkify="true"
                :inline="true"
                :text="item.text"
                :class="`text-${index}`"
                class="mt-4 mt-md-0"
                tag="p"
              />
            </div>
          </v-col>
        </v-row>
      </v-container>
    </template>
  </BasePage>
</template>

<script>
import BasePage from '@/organisms/BasePage'
import VideoPlayer from '@/molecules/VideoPlayer'
import VirtualTourQuery from '@/graphql/GetVirtualTour.gql'
import GetPageSeoQuery from '@/graphql/seo/GetVirtualTourPageSeo.gql'
import { MarkdownBlock } from '@monogrid/vue-lib'
import { getLazySrc, optimizedImageURL } from '@/services/OptimizedImage'

export default {
  name: 'VirtualTourPage',
  components: { BasePage, VideoPlayer, MarkdownBlock },
  apollo: {
    VirtualTour: {
      query: VirtualTourQuery,
      variables: {
        slug: 'globals'
      }
    },
    PageSeo: {
      query: GetPageSeoQuery,
      variables: {
        slug: 'globals'
      }
    }
  },
  methods: {
    getLazySrc,
    optimizedImageURL
  },
  metaInfo: function () {
    if (this.PageSeo) {
      let meta = [
        { vmid: 'ogtitle', property: 'og:title', itemprop: 'name', content: this.PageSeo.virtualTourMetaTitle },
        { vmid: 'description', name: 'description', content: this.PageSeo.virtualTourMetaDescription },
        { vmid: 'ogdescription', property: 'og:description', content: this.PageSeo.virtualTourMetaDescription }
      ]

      if (this.PageSeo.virtualTourMetaImage) {
        meta = meta.concat([
          { vmid: 'ogimage', property: 'og:image', content: 'https://' + this.$root.process.env.SERVICE_TARGET_DOMAIN + optimizedImageURL(this.PageSeo.virtualTourMetaImage, { width: 1200, height: 625, format: 'jpg', fit: 'crop' }) },
          { vmid: 'ogimagetype', property: 'og:image:type', content: 'image/jpeg' },
          { vmid: 'ogimageheight', property: 'og:image:width', content: '1200' },
          { vmid: 'ogimagewidth', property: 'og:image:height', content: '675' }
        ])
      }

      return {
        title: this.PageSeo.virtualTourMetaTitle,
        meta
      }
    }
  }
}
</script>

<style lang="scss">
.virtual-tour {
  overflow-y: auto;
}

.vt-bg-1 {
  position: absolute;
  width: rem(750px);
  height: rem(750px);
  background: $bg-sci-gradient-2;
  left: 40%;
  top: 50%;
  transform: translate(-40%, -50%);
}

.vt-bg-2 {
  position: absolute;
  width: rem(750px);
  height: rem(750px);
  background: $bg-emo-gradient-1;
  left: rem(250px);
  left: 60%;
  top: 50%;
  transform: translate(-60%, -50%);
}

.vt-media {
  max-width: 740px;
  margin: auto;

  @include breakpoint ('md-and-up') {
    width: 33%;

    &.vt-media-0,
    &.vt-media-1,
    &.vt-media-2 {
      position: absolute;
    }

    &.vt-media-0 {
      top: 0;
      right: 0;
      z-index: 1;
    }

    &.vt-media-1 {
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      z-index: 2;
    }

    &.vt-media-2 {
      right: 45%;
      bottom: 0;
      transform: translateX(45%);
      z-index: 1;
    }
  }

  &.vt-media-vertical {
    max-width: 250px;
    margin: 0 auto;

    @include breakpoint ('lg-only') {
      max-width: 220px;
    }
  }
}

p {
  @include breakpoint ('md-and-up') {
    width: 100%;
    position: absolute;

    &.text-0 {
      text-align: right;
      right: 0;
      bottom: -60px;
      z-index: 1;
    }

    &.text-1 {
      top: -60px;
      left: 0;
      z-index: 1;
    }

    &.text-2 {
      width: 200%;
      left: calc(100% + 30px);
      bottom: 0;
      z-index: 1;
    }
  }
}

</style>
